import home from '../styles/home.module.css'

export default function Servicio({ route, title, des}) {
    return (
        <div className={ home.servicios_el }>
            <img src={ "/images/servicios/" + route + ".png"} alt={ title } />
            <div className={ home.servicios_el__title}>{ title }</div>
            <div className={ home.servicios_el__des }>{ des }</div>
        </div>
    )
}