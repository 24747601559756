'use client'
import tr from '../styles/tratamiento.module.css'

export default function DescripcionEl({ imgSrc, des }) {
    const text = des.split("/");

    return (
        <div className={ tr.description_el }>
            <img src={ '/images/servicios/' + imgSrc + '.png' } alt='icon'/>
            { des.includes("/")
                ? <div className={ tr.description_el__des }>{ text[0] }<br/>{ text[1] }</div>
                : <div className={ tr.description_el__des }>{ des }</div>
            }
        </div>
    )
}