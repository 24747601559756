import tr from '../styles/tratamiento.module.css'
import cx from "classnames"

export default function Header({ route, title }) {
    const titles = title.split('/');

    return (
        <div className={ tr.tratamiento_header }>
            <div className={ tr.tratamiento_header_shadow }></div>
            <picture>
                <source type="image/webp" srcSet={"/images/" + route + "/hero@1920.webp"} media="(min-width:1500px)"/>
                <source type="image/png" srcSet={"/images/" + route + "/hero@1920.png"} media="(min-width:1500px)"/>
                <source type="image/webp" srcSet={"/images/" + route + "/hero@1500.webp"} media="(max-width:1499px) and (min-width: 1200px)"/>
                <source type="image/png" srcSet={"/images/" + route + "/hero@1500.png"} media="(max-width:1499px) and (min-width: 1200px)"/>
                <source type="image/webp" srcSet={"/images/" + route + "/hero@1200.webp"} media="(max-width:1199px) and (min-width: 900px)"/>
                <source type="image/png" srcSet={"/images/" + route + "/hero@1200.png"} media="(max-width:1199px) and (min-width: 900px)"/>
                <source type="image/webp" srcSet={"/images/" + route + "/hero@900.webp"} media="(max-width:899px) and (min-width: 600px)"/>
                <source type="image/png" srcSet={"/images/" + route + "/hero@900.png"} media="(max-width:899px) and (min-width: 600px)"/>
                <img src={"/images/" + route + "/hero@600.png"} srcSet={"/images/" + route + "/hero@600.webp"} alt="Tratamiento header" className="header__bg"/>
            </picture>
            <div className={ tr.tratamiento_header_des }>
                <div className={ tr.tratamiento_header_des__t1 }>{ titles[0] }</div>
                <div className={ tr.tratamiento_header_des__t2 }>{ titles[1] }</div>
            </div>
        </div>
    )
}