import React from "react"
import ReactDOM from "react-dom/client"
import ScrollToTop from "./components/ScrollToTop"
import { createBrowserRouter ,BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home"
import AlcoholismoDrogas from "./pages/alcoholismo-drogas"
import AdultosMayores from "./pages/adultos-mayores";
import CodependenciaLudopatia from "./pages/codependencia-ludopatia";
import AlimenticiosPsicologicos from "./pages/alimenticios-psicologicos";
import Tratamiento from "./pages/tratamiento"

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>
    },
    {
        path: '/alcoholismo-drogas',
        element: <AlcoholismoDrogas/>
    },
    {
        path: '/tratamiento/:tratamiento',
        element: <Tratamiento/>
    },
    {
        path: "*",
        element: <div>Página no encontrada</div>
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={ <Home/> }/>
                <Route path="/alcoholismo-drogas" element={ <AlcoholismoDrogas/> }/>
                <Route path="/adultos-mayores" element={ <AdultosMayores/> }/>
                <Route path="/codependencia-ludopatia" element={ <CodependenciaLudopatia/> }/>
                <Route path="/alimenticios-psicologicos" element={ <AlimenticiosPsicologicos/> }/>
                <Route path="*" element={ <div>Página no encontrada</div> }/>
            </Routes>
        </BrowserRouter>
    </>
);