'use client'

const menu = () => { document.querySelector('.mobile').classList.toggle('active') }

export function MenuHanburguer() {
    return (
        <div onClick={ menu } className="navbar_mobile">
            <img src="/images/icon.svg" alt="Hamburguer icon" />
        </div>
    )
}

export function CloseMobile() {
    return (
        <img onClick={ menu } className="mobile_close" src="/images/close.svg" alt="Close icon"/>
    )
}