import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { Navbar } from "../components/Navigation";
import { Tratamientos } from "../array/tratamientos";

export default function Tratamiento() {
    const { tratamiento } = useParams();
    const actual = Tratamientos.find(element => element.id == tratamiento);

    return (
        <>
            <Navbar/>
            <Header route={ actual.id } title={ actual.title }/>
            <div>{ actual.id }</div>
        </>
    )
}