export function Footer() {
    return (
        <div className="footer section">
            <div className="content">
                <div className="footer_des">
                    <div className="footer_des__t1 title">¿Listos para la recuperación?</div>
                    <div className="footer_des__t2">Estamos aquí para ayudarte a dar el primer paso</div>
                    <div className="footer_des_social">
                        <div className="footer_des_social_el">
                            <img src="/images/footer/tel.png" alt="Teléfono" className="footer_des_social_el__icon"/>
                            <div className="footer_des_social_el__des">Llámanos al teléfono <a href="tel:6691529796">(669) 152 97 96</a></div>
                        </div>
                        <div className="footer_des_social_el">
                            <img src="/images/footer/fb.png" alt="Facebook" className="footer_des_social_el__icon"/>
                            <div className="footer_des_social_el__des">¿Deseas más información? Te compartimos nuestras redes sociales: <a href="https://www.facebook.com/clinica.samead.1" target="_blank">@clinica.samead.1</a></div>
                        </div>
                        <div className="footer_des_social_el">
                            <img src="/images/footer/correo.png" alt="Correo" className="footer_des_social_el__icon"/>
                            <div className="footer_des_social_el__des">Envíanos un correo al <a href="mailto:contacto@cisamead.com">contacto@cisamead.com</a></div>
                        </div>
                    </div>
                </div>
                <div className="footer_map">
                    <a href="https://goo.gl/maps/5NTPEHnu6QRsASbT7" target="_blank">
                        <img src="/images/footer/mapa.png" alt="Mapa SAMEAD"/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export function Copyright() {
    return (
        <div className="copyright">Todos los derechos reservados. SAMEAD 2023.</div>
    )
}