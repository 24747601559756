import '../styles/global.css'
import home from '../styles/home.module.css'
import { Navbar, MenuMobile } from '../components/Navigation'
import { Link } from 'react-router-dom'
import cx from "classnames"
import Servicio from '../components/Servicio'
import { Footer, Copyright } from '../components/Footer'

export default function Home() {
    return (
        <>
            <Navbar/>
            <MenuMobile/>

            {/* Header */}
            <div className={ home.header }>
                <div className={ home.header_shadow }></div>
                <img className={ home.header_bg } src="/images/home/hero@1920.png" srcSet="/images/home/hero@1920.webp" alt="Mujer con mano al cielo" />
                <div className={ home.header_des }>
                    <div className={ home.header_des__t1 }>Es posible</div>
                    <div className={ home.header_des__t2 }>ser libre</div>
                </div>
            </div>

            {/* Espacio humano */}
            <div id='nosotros' className={ home.espacio + ' section'}>
                <img className={ home.espacio_bg } src="/images/home/espacio.png" srcSet="/images/home/espacio.webp" alt="Espacio background"/>
                <div className={ home.espacio_content + ' content'}>
                    <div className={ home.espacio__t1 }>Salud Mental y Adicciones</div>
                    <div className={ cx(home.espacio__t2, home.title) }>Un Espacio Humano</div>
                    <div className={ home.espacio__sep }></div>
                    <div className={ home.espacio__t3 }>Nos dedicamos dedica a apoyar , ayudar, guiar y fortalecer a las personas que lo necesiten.</div>
                    <Link to="/" className={ home.espacio__link }>CONTÁCTANOS</Link>
                </div>
            </div>

            {/* Salud y procesos */}
            <div id='procesos' className={ cx(home.salud, home.card) }>
                <div className={ home.card_des }>
                    <div className={ cx(home.card_des__t1, home.title) }>Tu Salud, Nuestra Vocación</div>
                    <div className={ home.card_des__t2 }>No combatas la adicción solo</div>
                    <div className={ home.card_des__t3 }>Las adicciónes pueden parecer abrumadoras. Pero la recuperación no es solo algo que puede lograr, es algo que se merece.</div>
                    <div className={ home.card_des__t3 }>SAMEAD está aquí para ayudarlo a vivir más allá. Tu mejor vida está lista para abrazarte.</div>
                </div>
                <div className={ home.card_img }>
                    <img src="/images/home/salud.png" alt="Persona en tratamiento" />
                </div>
            </div>
            <div className={ cx(home.procesos, home.card) }>
                <div className={ home.card_des }>
                    <div className={ cx(home.card_des__t1, home.title) }>Procesos</div>
                    <div className={ home.card_des__t2 }>Les ayudamos a vivir con la mejor calidad</div>
                    <div className={ home.card_des__dot }>
                        <img src="/images/home/residencia_icon.png" alt="Programa residencia" />
                        <div className={ home.card_des__t3 }>Programa residencia</div>
                    </div>
                    <div className={ home.card_des__dot }>
                        <img src="/images/home/apoyo_icon.png" alt="Apoyo familiar" />
                        <div className={ home.card_des__t3 }>Apoyo familiar</div>
                    </div>
                    <div className={ home.card_des__dot }>
                        <img src="/images/home/recuperacion_icon.png" alt="Programa de recuperación" />
                        <div className={ home.card_des__t3 }>Programa de recuperación</div>
                    </div>
                    <Link className={ home.card_des__link } to="/">CONTÁCTANOS</Link>
                </div>
                <div className={ home.card_img }>
                    <img src="/images/home/procesos.png" srcSet="/images/home/procesos.webp" alt="Manos"/>
                </div>
            </div>

            {/* Nuestros servicios */}
            <div id='servicios' className={ home.servicios + " section"}>
                <div className='content'>
                    <div className={ cx(home.servicios_title, home.title) }>Nuestros servicios</div>
                    <div className={ home.servicios_des }>Los tratamientos en SAMEAD son 100% personalizados para cada paciente. Estamos para apoyar, guiar y ayudar a las personas con adicciones.</div>
                    <div className={ home.servicios_con }>
                        <Servicio route="detox" title="Desintoxicación" des="El objetivo es conseguir que el paciente que sufre adicción a una sustancia tóxica suspenda su consumola forma más rápida y segura posible."/>
                        <Servicio route="terapia" title="Terapia individual y en grupo" des="En la terapia individual el paciente solo interactua con el psicólogo, mientras que en la grupal existen la interacción entre pacientes."/>
                        <Servicio route="residencial" title="Tratamiento Residencial" des="Implica vivir en un entorno libre de alcohol y drogas mientras se recupera de su trastorno por consumo de sustancias."/>
                        <Servicio route="descanso" title="Casa de descanso para adultos mayores" des="Personas con necesidad de apoyo por su deterioro físico, o su deterioro, mental."/>
                    </div>
                </div>
            </div>

            {/* Tratamientos */}
            <div id='tratamientos' className={ home.tratamientos + ' section'}>
                <div className={ home.tratamientos_des }>
                    <div className={ cx(home.tratamientos_des__t1, home.title) }>Tratamientos</div>
                    <div className={ home.tratamientos_des__t2 }>Nuestro equipo de profesionales se dedica a apoyar , ayudar, guiar y fortalecer a las personas con alguna de estas enfermedades para que puedan ser incorporados a la sociedad sin ningún problema.</div>
                    <Link to="alimenticios-psicologicos" className={ home.tratamientos_des__el }><span></span>Transtornos alimenticios y psicológicos</Link>
                    <Link to="alcoholismo-drogas" className={ home.tratamientos_des__el }><span></span>Alcoholismo y drogadicción</Link>
                    <Link to="codependencia-ludopatia" className={ home.tratamientos_des__el }><span></span>Codependencia y ludopatía</Link>
                    <Link to="adultos-mayores" className={ home.tratamientos_des__el }><span></span>Casa para adultos mayores</Link>
                </div>
                <div className={ home.tratamientos_img }><img src="/images/home/tratamientos.png" alt="Tratamientos image"/></div>
            </div>

            <Footer/>
            <Copyright/>
        </>
    )
}