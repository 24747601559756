import Popup from 'reactjs-popup'
import tr from '../styles/tratamiento.module.css'
import cx from "classnames"
import DescripcionEl from '../components/DescripcionEl'
import { Navbar } from "../components/Navigation"
import Header from "../components/Header"
import { Footer, Copyright } from '../components/Footer'

export default function AlcoholismoDrogas() {
    return (
        <>
            <Navbar/>
            <Header route="alcoholismo-drogas" title="Alcoholismo/y drogadicción"/>

            <div className={ tr.description }>
                <div className={ tr.description_con }>
                    <DescripcionEl imgSrc="detox" des="Programa de recuperación y desintoxicación"/>
                    <DescripcionEl imgSrc="terapia" des="Terapia individual/y en grupo"/>
                    <DescripcionEl imgSrc="residencial" des="Tratamiento/residencial"/>
                    <DescripcionEl imgSrc="apoyo-familiar" des="Apoyo/familiar"/>
                </div>
            </div>

            {/* Servicio */}
            <div className={ cx(tr.servicio, tr.servicio_light) }>
                <div className={ tr.servicio_img }>
                    <img src='/images/alcoholismo-drogas/alcoholismo.png' alt='Alcoholismo' />
                </div>
                <div className={ tr.servicio_des }>
                    <div className={ tr.servicio_des__t1 }>Alcoholismo</div>
                    <div className={ tr.servicio_des__t2 }>Hablar de alcoholismo cuando todos podemos pensar que es muy normal ingerir alcohol y sobre todo en lugares como puertos donde se glorifica mas por el calor y la playa.</div>
                    <div className={ tr.servicio_des__t2 }>El ingerir bebidas alcohólicas que aparte pertenecen al grupo de las legales no es el problema.</div>
                    <Popup trigger={ <div className={ tr.servicio_des__btn }>+ Ver más</div> } position="top center">
                        <div className={ tr.info }>
                            <div className={ tr.info_title }>Alcoholismo</div>
                            <div className={ tr.info_text }>Hablar de alcoholismo cuando todos podemos pensar que es muy normal ingerir alcohol y sobre todo en lugares como puertos donde se glorifica mas por el calor y la playa.</div>
                            <div className={ tr.info_text }>El ingerir bebidas alcohólicas que aparte pertenecen al grupo de las legales no es el problema.</div>
                            <div className={ tr.info_text }>El problema es cuando al ingerir el alcohol nos lleva a vivir experiencias desastrosas, agobiantes, preocupantes de cualquier tipo (Familiares, Automovilísticos, de Pareja, Laborales, Legales) y a pesar de estas situaciones no hay manera de dejar el consumo de alcohol porque se cree que no es tan dañino; y si para mucha gente no lo es; pero para los que tenemos un cerebro susceptible si lo es.</div>
                            <div className={ tr.info_text }>Aquí es exactamente donde entramos el equipo SAMEAD para poder ayudarte y apoyarte para salir de este infierno donde te encuentras y que no esta en ti, poder salir SOLO, por los pretextos que quieras, hay una infinidad de ellos.</div>
                            <div className={ tr.info_text }>El verdadero problema no es este tampoco sino el de no querer salir del hoyo, porque cuando no queremos algo en la vida buscamos pretextos y cuando si queremos algo en la vida buscamos soluciones.</div>
                            <div className={ tr.info_text }>Y este problema si tiene solución!!!!!</div>
                            <div className={ tr.info_text }>También hacemos presente los problemas físicos que este problema trae como presión arterial, enfermedades cardiacas, accidentes cerebrovasculares, enfermedad del hígado y problemas digestivos.</div>
                            <div className={ tr.info_text }>Cáncer de mama, boca, garganta, laringe, esófago, hígado, colon y recto.</div>
                            <div className={ tr.info_text }>Problemas mentales, el cerebro se empieza a deteriorar y se inicia la manera de ver las cosas distorsionadas y por lo tanto se toman decisiones nefastas en contra de nosotros mismos y de los demás ósea el cerebro deja de funcionar correctamente, con alucinaciones auditivas y visuales, depresión, ansiedad, confusión, locura parcial y permanente.</div>
                        </div>
                    </Popup>
                </div>
            </div>

            {/* Servicio */}
            <div className={ cx(tr.servicio, tr.servicio_dark) }>
                <div className={ tr.servicio_des }>
                    <div className={ tr.servicio_des__t1 }>Drogadicción</div>
                    <div className={ tr.servicio_des__t2 }>Metanfetaminas, Anfetaminas, Cocaína, Marihuana, Opiáceos (Morfina, Fentanilo, Heroína), PCP (Polvo de ángel, anestésico, alucinógeno) Benzodiacepinas, Inhalantes, Barbitúricos, XTC (Éxtasis).</div>
                    <div className={ tr.servicio_des__t2 }>Todo este tipo de drogas al igual que el alcohol afectan al Sistema Nervioso Central.</div>
                    <div className={ tr.servicio_des__t2 }>Actúan sobre los neurotransmisores alterando y perturbando el correcto funcionamiento, afectando la conducta, estado de ánimo y percepción.</div>
                    <Popup trigger={ <div className={ tr.servicio_des__btn }>+ Ver más</div> } position="top center">
                        <div className={ tr.info }>
                            <div className={ tr.info_title }>Drogadicción</div>
                            <div className={ tr.info_text }>Metanfetaminas, Anfetaminas, Cocaína, Marihuana, Opiáceos (Morfina, Fentanilo, Heroína), PCP (Polvo de ángel, anestésico, alucinógeno) Benzodiacepinas, Inhalantes, Barbitúricos, XTC (Éxtasis).</div>
                            <div className={ tr.info_text }>Todo este tipo de drogas al igual que el alcohol afectan al Sistema Nervioso Central.</div>
                            <div className={ tr.info_text }>Actúan sobre los neurotransmisores alterando y perturbando el correcto funcionamiento, afectando la conducta, estado de ánimo y percepción.</div>
                            <div className={ tr.info_text }>Además son susceptibles de crear dependencia física y/o psicológica.</div>
                            <div className={ tr.info_text }>Aquí es donde entramos nosotros SAMEAD para apoyar y ayudar a salir de este torbellino al que te llevan antes de terminar en un Psiquiátrico, Panteón o Cárcel.</div>
                            <div className={ tr.info_text }>Los problemas físicos que trae esta problemática son Insomnio, Anorexia, Disminución lívido, comportamiento violento, accidentes cerebrovasculares, automovilísticos, alucinaciones auditivas y visuales, Psicosis.</div>
                        </div>
                    </Popup>
                </div>
                <div className={ tr.servicio_img }>
                    <img src='/images/alcoholismo-drogas/drogadiccion.png' alt='Drogadiccion' />
                </div>
            </div>

            <Footer/>
            <Copyright/>
        </>
    )
}