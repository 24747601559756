import { Link } from "react-router-dom"
import { MenuHanburguer, CloseMobile } from "./NavigationButtons"
import { HashLink } from "react-router-hash-link"

export function Navbar() {
    return (
        <div className="navbar section">
            <div className="content">
                <div className="navbar_links">
                    <HashLink smooth to="/#nosotros" className="navbar_links__el">NOSOTROS</HashLink>
                    <HashLink smooth to="/#procesos" className="navbar_links__el">PROCESOS</HashLink>
                </div>
                <Link className="navbar_logo" to="/">
                    <img src="/images/logo.png" alt="Logo SAMEAD" />
                </Link>
                <div className="navbar_links">
                    <HashLink smooth to="/#servicios" className="navbar_links__el">SERVICIOS</HashLink>
                    <HashLink smooth to="/#tratamientos" className="navbar_links__el">TRATAMIENTOS</HashLink>
                </div>
                <MenuHanburguer/>
            </div>
        </div>
    )
}

export function MenuMobile() {
    return (
        <div className="mobile">
            <CloseMobile/>
            <Link to="/" className="mobile_el">HOME</Link>
            <HashLink to="/#nosotros" className="mobile_el">NOSOTROS</HashLink>
            <HashLink to="/#procesos" className="mobile_el">PROCESOS</HashLink>
            <HashLink to="/#servicios" className="mobile_el">SERVICIOS</HashLink>
            <HashLink to="/#tratamientos" className="mobile_el">TRATAMIENTOS</HashLink>
            <div className="mobile_contact">
                <a href="tel:6691529796"><img src="/images/footer/tel.png" alt="Teléfono"/></a>
                <a href="https://www.facebook.com/clinica.samead.1" target="_blank"><img src="/images/footer/fb.png" alt="Facebook"/></a>
                <a href="mailto:contacto@cisamead.com"><img src="/images/footer/correo.png" alt="Correo"/></a>
            </div>
        </div>
    )
}