import Popup from 'reactjs-popup'
import tr from '../styles/tratamiento.module.css'
import cx from "classnames"
import { Navbar } from "../components/Navigation"
import Header from "../components/Header"
import DescripcionEl from "../components/DescripcionEl"
import { Footer, Copyright } from "../components/Footer"

export default function CodependenciaLudopatia() {
    return (
        <>
            <Navbar/>
            <Header route="codependencia-ludopatia" title="Codependencia/y ludopatía"/>

            <div className={ tr.description }>
                <div className={ tr.description_con }>
                    <DescripcionEl imgSrc="detox" des="Programa de recuperación y desintoxicación"/>
                    <DescripcionEl imgSrc="terapia" des="Terapia individual/y en grupo"/>
                    <DescripcionEl imgSrc="residencial" des="Tratamiento/residencial"/>
                    <DescripcionEl imgSrc="apoyo-familiar" des="Apoyo/familiar"/>
                </div>
            </div>

            {/* Servicio */}
            <div className={ cx(tr.servicio, tr.servicio_light) }>
                <div className={ tr.servicio_img }>
                    <img src='/images/codependencia-ludopatia/codependencia.png' alt='Codependencia' />
                </div>
                <div className={ tr.servicio_des }>
                    <div className={ tr.servicio_des__t1 }>Codependencia</div>
                    <div className={ tr.servicio_des__t2 }>Sentimientos de soledad, culpa, vacío, frustración, miedo, baja autoestima.</div>
                    <div className={ tr.servicio_des__t2 }>Síntomas tendencia a pensar en el bienestar del otro, sin tener en cuenta sus propias necesidades.</div>
                    <div className={ tr.servicio_des__t2 }>Un sentido exagerado de responsabilidad por las acciones de los otros. Tendencia a confundir el amor y la lástima y rescatar por amor a personas que les dan lástima.</div>
                    <Popup trigger={ <div className={ tr.servicio_des__btn }>+ Ver más</div> } position="top center">
                        <div className={ tr.info }>
                            <div className={ tr.info_title }>Codependencia</div>
                            <div className={ tr.info_text }>Sentimientos de soledad, culpa, vacío, frustración, miedo, baja autoestima.</div>
                            <div className={ tr.info_text }>Síntomas tendencia a pensar en el bienestar del otro, sin tener en cuenta sus propias necesidades.</div>
                            <div className={ tr.info_text }>Un sentido exagerado de responsabilidad por las acciones de los otros. Tendencia a confundir el amor y la lástima y rescatar por amor a personas que les dan lástima.</div>
                            <div className={ tr.info_text }>Dificultad para poner límites por la represión de sus emociones, por hacer propios los problemas del otro por la negación.</div>
                            <div className={ tr.info_text }>Se les dificulta decir NO, necesita reforzar su autoestima, aprender a estar SOLO, cuestionarse sus propias creencias, cultivar vida social.</div>
                            <div className={ tr.info_text }>Pedir ayuda y apoyo para logar su autoestima en su lugar y es ahí donde entramos nosotros SAMEAD para trabajar con la persona que tiene este tipo de problemática.</div>
                        </div>
                    </Popup>
                </div>
            </div>

            {/* Servicio */}
            <div className={ cx(tr.servicio, tr.servicio_dark) }>
                <div className={ tr.servicio_des }>
                    <div className={ tr.servicio_des__t1 }>Ludopatía</div>
                    <div className={ tr.servicio_des__t2 }>Con los daños que nos encontramos son problemas de relaciones, familiares, económicos como la bancarrota, legales o de encarcelamiento.</div>
                    <div className={ tr.servicio_des__t2 }>SAMEAD ofrece el tratamiento adecuado y personalizado para cada una de estas situaciones que se nos presentan sin tener información y el proceso que se necesita para salir de esta problemática.</div>
                    <Popup trigger={ <div className={ tr.servicio_des__btn }>+ Ver más</div> } position="top center">
                        <div className={ tr.info }>
                            <div className={ tr.info_title }>Ludopatía</div>
                            <div className={ tr.info_text }>Este autoconvencimiento no es más que la idea errónea de que por una vez mas que jueguen no pasara nada; en este momento su cerebro deja de funcionar de manera racional y entra en una distorsión de la realidad que lo llevará a pensar que pueden volver a jugar de manera controlada.</div>
                            <div className={ tr.info_text }>Este tipo de Adicción pese a no ser del tipo tóxico (Adicción a drogas), sigue en rasgos generales el mismo curso; nuestro cerebro reacciona de manera similar ante la exposición y el consumo del juego frente al consumo de sustancias como el alcohol, cocaína o cannabis.</div>
                            <div className={ tr.info_text }>Con los daños que nos encontramos son problemas de relaciones, familiares, económicos como la bancarrota, legales o de encarcelamiento.</div>
                            <div className={ tr.info_text }>SAMEAD ofrece el tratamiento adecuado y personalizado para cada una de estas situaciones que se nos presentan sin tener información y el proceso que se necesita para salir de esta problemática.</div>
                        </div>
                    </Popup>
                </div>
                <div className={ tr.servicio_img }>
                    <img src='/images/codependencia-ludopatia/ludopatia.png' alt='Ludopatía' />
                </div>
            </div>

            <Footer/>
            <Copyright/>
        </>
    )
}