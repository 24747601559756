import tr from '../styles/tratamiento.module.css'
import cx from "classnames"
import Popup from 'reactjs-popup'
import { Navbar } from "../components/Navigation"
import Header from "../components/Header"
import DescripcionEl from "../components/DescripcionEl"
import { Footer, Copyright } from "../components/Footer"

export default function AdultosMayores() {
    return(
        <>
            <Navbar/>
            <Header route="adultos-mayores" title="Casa de descanso/adultos mayores"/>

            <div className={ tr.description }>
                <div className={ tr.description_con }>
                    <DescripcionEl imgSrc="terapia" des="Terapia individual/y en grupo"/>
                    <DescripcionEl imgSrc="residencial" des="Tratamiento/residencial"/>
                    <DescripcionEl imgSrc="apoyo-familiar" des="Apoyo/familiar"/>
                </div>
            </div>

            {/* Servicio */}
            <div className={ cx(tr.servicio, tr.servicio_light) }>
                <div className={ tr.servicio_img }>
                    <img src='/images/adultos-mayores/adultos-mayores.png' alt='Adultos mayores' />
                </div>
                <div className={ tr.servicio_des }>
                    <div className={ tr.servicio_des__t1 }>Casa de descanso para adultos mayores</div>
                    <div className={ tr.servicio_des__t2 }>Contamos con una casa de descanso para adultos mayores de todo tipo de personas con necesidad de apoyo por su deterioro físico, o su deterioro, mental.</div>
                    <div className={ tr.servicio_des__t2 }>Especializados en la enfermedad de Alzheimer, Demencia Senil, Parkinson y enfermedades de todo tipo en la edad adultos mayores.</div>
                    <Popup trigger={ <div className={ tr.servicio_des__btn }>+ Ver más</div> } position="top center">
                        <div className={ tr.info }>
                            <div className={ tr.info_title }>Casa de descanso para adultos mayores</div>
                            <div className={ tr.info_text }>Contamos con una casa de descanso para adultos mayores de todo tipo de personas con necesidad de apoyo por su deterioro físico, o su deterioro, mental.</div>
                            <div className={ tr.info_text }>Especializados en la enfermedad de Alzheimer, Demencia Senil, Parkinson y enfermedades de todo tipo en la edad adultos mayores.</div>
                            <div className={ tr.info_text }>Trabajamos en SAMEAD para proporcionales una buena calidad de vida, asistiéndolos en este tipo de enfermedades crónicas, progresivas y mortales; facilitándoles su existencia por medio de Dinámicas, Estrategias de  Movimiento Físico y Mental, Juegos, Terapias existenciales, Espirituales, fisioterapias, Terapias grupales, individuales, llevándolos a vivir en  plenitud a pesar de sus enfermedades, con respeto, amor, cuidados por equipo capacitado para este tipo de Adultos Mayores que los necesitan, así como un equipo de Médicos, Enfermeras, Psicólogos y Cuidadores.</div>
                            <div className={ tr.info_text }>Todo por una buena calidad de vida con acceso diario a sus familiares vía telefónica, por visitas o para salir del Centro a pasar un tiempo con la Familia.</div>
                            <div className={ tr.info_text }>Llámanos estamos para servirte.</div>
                        </div>
                    </Popup>
                </div>
            </div>

            <Footer/>
            <Copyright/>
        </>
    )
}