import Popup from 'reactjs-popup'
import tr from '../styles/tratamiento.module.css'
import cx from "classnames"
import DescripcionEl from '../components/DescripcionEl'
import { Navbar } from "../components/Navigation"
import Header from "../components/Header"
import { Footer, Copyright } from '../components/Footer'

export default function AlimenticiosPsicologicos() {
    return (
        <>
            <Navbar/>
            <Header route="alimenticios-psicologicos" title="Transtornos alimenticios/y psicológicos"/>

            <div className={ tr.description }>
                <div className={ tr.description_con }>
                    <DescripcionEl imgSrc="detox" des="Programa de recuperación y desintoxicación"/>
                    <DescripcionEl imgSrc="terapia" des="Terapia individual/y en grupo"/>
                    <DescripcionEl imgSrc="residencial" des="Tratamiento/residencial"/>
                    <DescripcionEl imgSrc="apoyo-familiar" des="Apoyo/familiar"/>
                </div>
            </div>

            {/* Servicio */}
            <div className={ cx(tr.servicio, tr.servicio_light) }>
                <div className={ tr.servicio_img }>
                    <img src='/images/alimenticios-psicologicos/alimenticios.png' alt='Alimenticios' />
                </div>
                <div className={ tr.servicio_des }>
                    <div className={ tr.servicio_des__t1 }>Transtornos de la conducta alimentaria</div>
                    <div className={ tr.servicio_des__t2 }>Anorexia, Bulimia, Comedor Compulsivo, Trastorno de la Conducta Alimentaria con espectro Mixto (Bulimia y Anorexia), Trastorno de la Conducta Alimentaria con espectro Bulímico, Trastorno de la Conducta Alimentaria con espectro Anoréxico.</div>
                    <div className={ tr.servicio_des__t2 }>Los atracones pueden llevar a la ganancia de peso, irregularidades en la menstruales, crecimiento de las glándulas salivales, letargia, ansiedad, depresión, culpa.</div>
                    <Popup trigger={ <div className={ tr.servicio_des__btn }>+ Ver más</div> } position="top center">
                        <div className={ tr.info }>
                            <div className={ tr.info_title }>Transtornos de la conducta alimentaria</div>
                            <div className={ tr.info_text }>Anorexia, Bulimia, Comedor Compulsivo, Trastorno de la Conducta Alimentaria con espectro Mixto (Bulimia y Anorexia), Trastorno de la Conducta Alimentaria con espectro Bulímico, Trastorno de la Conducta Alimentaria con espectro Anoréxico.</div>
                            <div className={ tr.info_text }>Los atracones pueden llevar a la ganancia de peso, irregularidades en la menstruales, crecimiento de las glándulas salivales, letargia, ansiedad, depresión, culpa.</div>
                            <div className={ tr.info_text }>Las conductas compensatorias (purgas), se pueden manifestar como pérdida de peso, erosión dental, diarrea, dolor de cabeza, mareos, confusión y ansiedad.</div>
                            <div className={ tr.info_text }>Nutrición adecuada, problemas de salud; cardiaco, renales incluso la muerte, desnutrición, peso bajo.</div>
                            <div className={ tr.info_text }>Baja tensión arterial o hipotensión, pulso lento o frecuencia cardiaca irregular.</div>
                            <div className={ tr.info_text }>En SAMEAD tratamos cada una de estas enfermedades personalizadas y con las terapias necesarias para enfrentarlas y salir de ese infierno oscuro donde nos encontramos hasta ver la luz y superar y trascender esta situación que nos toco vivir esta experiencia para aprender lo que necesitamos en esta vida.</div>
                        </div>
                    </Popup>
                </div>
            </div>

            {/* Servicio */}
            <div className={ cx(tr.servicio, tr.servicio_dark) }>
                <div className={ tr.servicio_des }>
                    <div className={ tr.servicio_des__t1 }>Transtornos psiquiátricos</div>
                    <div className={ tr.servicio_des__t2 }>-Trastorno Desafiante de la Personalidad</div>
                    <div className={ tr.servicio_des__t2 }>-Trastorno Déficit de Atención con y sin Hiperactividad</div>
                    <div className={ tr.servicio_des__t2 }>-Trastorno Explosivo Intermitente</div>
                    <div className={ tr.servicio_des__t2 }>-Trastorno Obsesivo-Compulsivo</div>
                    <div className={ tr.servicio_des__t2 }>-Trastorno de Ansiedad</div>
                    <div className={ tr.servicio_des__t2 }>-Trastornos Mentales</div>
                    <Popup trigger={ <div className={ tr.servicio_des__btn }>+ Ver más</div> } position="top center">
                        <div className={ tr.info }>
                            <div className={ tr.info_title }>Transtornos psiquiátricos</div>
                            <div className={ tr.info_text }>-Trastorno Desafiante de la Personalidad</div>
                            <div className={ tr.info_text }>-Trastorno Déficit de Atención con y sin Hiperactividad</div>
                            <div className={ tr.info_text }>-Trastorno Explosivo Intermitente</div>
                            <div className={ tr.info_text }>-Trastorno Obsesivo-Compulsivo</div>
                            <div className={ tr.info_text }>-Trastorno de Ansiedad</div>
                            <div className={ tr.info_text }>-Trastornos Mentales</div>
                            <div className={ tr.info_text }>- Trastorno Depresivo Mayor</div>
                            <div className={ tr.info_text }>- Trastorno Limite de la Personalidad</div>
                            <div className={ tr.info_text }>- Trastorno Psicológico</div>
                            <div className={ tr.info_text }>- Trastornos Emocionales</div>
                            <div className={ tr.info_text }>- Trastornos del Sueño</div>
                            <div className={ tr.info_text }>- Trastornos de la Personalidad</div>
                            <div className={ tr.info_text }>- Trastornos Vasculares en el Cerebro</div>
                            <div className={ tr.info_text }>- Trastorno de la Hipófisis</div>
                            <div className={ tr.info_text }>- Trastorno Post-Traumático</div>
                            <div className={ tr.info_text }>- Todo este tipo de Trastornos los trabajamos aquí en SAMEAD, Con diferentes tipos de Terapias, Estrategias, Dinámicas, Grupos.</div>
                            <div className={ tr.info_text }>- Así como la parte Medica (Orgánica, Física, Psicológica, Mental, Emocional y Espiritual</div>
                        </div>
                    </Popup>
                </div>
                <div className={ tr.servicio_img }>
                    <img src='/images/alimenticios-psicologicos/psicologicos.png' alt='Psicológicos' />
                </div>
            </div>

            <Footer/>
            <Copyright/>
        </>
    )
}